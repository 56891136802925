import * as React from "react";
import { Link, graphql } from "gatsby";
import Shell from "../components/shell";
import { Grid, Row, Col, Button } from "armstrong-react";

import "./index.scss";
import InsightlyForm from "../components/insightlyForm";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Footer } from "../components/footer";
import { HubspotForm } from "../components/hubspotForm";

interface IProps {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            header: string
            header2: string
            intro: string
            info: string
            featurListHeader: string
            featureList: string[]
            shareHeader: string
            shareContactLink: string
            formHeader: string
            formIntro: string
            formButtonText: string
            formCloseDate: string
            leftBlock1Header: string
            leftBlock1Content: string
            leftBlock2Header: string
            leftBlock2Content: string
            leftBlock3Image: string
            leftBlock4Header: string
            leftBlock4Content: string
            rightBlock1Header: string
            rightBlock1SubHeader1: string
            rightBlock1List1: string[]
            rightBlock1SubHeader2: string
            rightBlock1List2: string[]
            rightBlock1SubHeader3: string
            rightBlock1List3: string[]
            rightBlock2Content: string
            rightBlock2Footer: string
            rightBlock3Image: string
          }
        }
      }[]
    }
  }
}
interface IState {
  isClientRender: boolean;
  needMoreInfo: boolean;
}

export default class extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { isClientRender: false, needMoreInfo: false }
  }
  componentDidMount() {
    this.setState({ isClientRender: true })
  }
  render() {
    let data = this.props.data.allMarkdownRemark.edges[0].node.frontmatter;
    return (
      <Shell>
        <Grid className="home-view">
          <Row className="fixed-width-inner rs-medium-1col">
            <Col className="content-panel">

              <img src={require('../assets/logo_darkblue.svg')} alt="Newfound" />
              <h2 className="first-header m-top-medium fg-brand-secondary m-bottom-medium">{data.header}</h2>
              <p className="strong fg-brand-tertiary m-bottom-medium p-top-medium">{data.header2}</p>
              <p>
                <span className="fg-brand-tertiary" style={{ fontSize: "18px", fontWeight: 500 }}>{data.intro}</span>
                <br /><br />
                {data.info}
              </p>
              <p className="intro p-top-large p-bottom-small fg-brand-quaternary">{data.featurListHeader}</p>
              <ul className="fg-brand-quaternary red-list">
                {data.featureList.map(f => <li>{f}</li>)}
              </ul>
              <p className="intro p-top-large p-bottom-xsmall fg-brand-quaternary">{data.shareHeader}</p>
              {data.shareContactLink && <a className="share-link" href={`mailto:${data.shareContactLink}`}>{data.shareContactLink}</a>}
              <div className="socials">
                <OutboundLink rel="noopener" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fbit.ly%2F2VxxmqP">
                  <img alt="Share on Facebook" src={require("../assets/facebook.svg")} />
                </OutboundLink>
                <OutboundLink rel="noopener" target="_blank " href="https://twitter.com/intent/tweet?url=%20http%3A%2F%2Fbit.ly%2F2VxxmqP&text=Expanding%20to%20the%20UK%3F%20Apply%20to%20win%20%C2%A325%2C000%20in%20research%2C%20analysis%20and%20market%20introductions%20to%20support%20your%20market%20entry%20http%3A%2F%2Fbit.ly%2F2VxxmqP">
                  <img alt="Share on Twitter" src={require("../assets/twitter.svg")} />
                </OutboundLink>
                <OutboundLink rel="noopener" target="_blank" href="http://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fbit.ly%2F2VxxmqP&title=Apply%20to%20win%20%C2%A325%2C000%20in%20research%2C%20analysis%20%2B%20market%20intros%20to%20support%20your%20UK%20market%20entry">
                  <img alt="Share on LinkedIn" src={require("../assets/linkedin.svg")} />
                </OutboundLink>
              </div>

            </Col>
            <Col className="side-panel no-flex">
              <div>
                <h2>{data.formHeader}</h2>
                <p>{data.formIntro}</p>
                <div className="home-form">
                  {/* <InsightlyForm buttonText={data.formButtonText} /> */}
                  <HubspotForm/>
                </div>
                <p className="application-date">{data.formCloseDate}</p>
              </div>
            </Col>
          </Row>
          <Row className="content-row rs-medium-1col">
            <Col className="twin-section">
              <div className="bg-brand-tertiary fg-brand-primary">
                <h2>{data.leftBlock1Header}</h2>
                <p className="long-header">
                  {data.leftBlock1Content}
                </p>
              </div>
              <div className="bg-brand-secondary fg-brand-primary no-mobile">
                <h2>{data.leftBlock2Header}</h2>
                <p>

                  {data.leftBlock2Content}
                </p>
              </div>
            </Col>
            <Col className="bg-brand-quinary double-section fg-brand-primary">
              <h2 className="fg-brand-primar">{data.rightBlock1Header}</h2>
              <p className="long-header">
                {data.rightBlock1SubHeader1}
              </p>
              <ul>
                {data.rightBlock1List1.map(f => <li>{f}</li>)}
              </ul>
              <p className="long-header">
                {data.rightBlock1SubHeader2}
              </p>
              <ul>
                {data.rightBlock1List2.map(f => <li>{f}</li>)}
              </ul>
              <p className="long-header">
                {data.rightBlock1SubHeader3}
              </p>
              <ul>
                {data.rightBlock1List3.map(f => <li>{f}</li>)}
              </ul>
            </Col>
            <Col className="mobile-only bg-brand-secondary fg-brand-primary">
              <h2>{data.leftBlock2Header}></h2>
              <p>
               {data.leftBlock2Content}
              </p>
            </Col>
          </Row>
          <Row className="content-row rs-medium-1col">
            <Col className="image-col" style={{ backgroundImage: `url(${data.leftBlock3Image})` }} />
            <Col className="fg-brand-quinary">
              <p className="long-header m-bottom-medium">
               {data.rightBlock2Content}
              </p>
              <p>{data.rightBlock2Footer}</p>
            </Col>
          </Row>
          <Row className="content-row rs-medium-1col">
            <Col className="bg-brand-quaternary fg-brand-primary">
              <h2>{data.leftBlock4Header}</h2>
              <p>
                {data.leftBlock4Content}
            </p>
            </Col>
            <Col className="image-col" style={{ backgroundImage: `url(${data.rightBlock3Image})` }} />
          </Row>
        </Grid>
        <Footer />
      </Shell>
    );
  }
}

export const query = graphql`
query {
  allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/content/pages/home.md" } }) {
    edges{
      node{
        frontmatter {
          header
          header2
          intro
          info
          featurListHeader
          featureList
          shareHeader
          shareContactLink
          formHeader
          formIntro
          formButtonText
          formCloseDate
          leftBlock1Header
          leftBlock1Content
          leftBlock2Header
          leftBlock2Content
          leftBlock3Image
          leftBlock4Header
          leftBlock4Content
          rightBlock1Header
          rightBlock1SubHeader1
          rightBlock1List1
          rightBlock1SubHeader2
          rightBlock1List2
          rightBlock1SubHeader3
          rightBlock1List3
          rightBlock2Content
          rightBlock2Footer
          rightBlock3Image
        }
      }
    }
  }
}`;
