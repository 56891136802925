import * as React from "react";
import { useForm } from 'react-hubspot'
import { CheckboxInput, Button } from "armstrong-react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Link, navigate } from "gatsby";


interface IState {
}

interface IProps{
}

// export class HubspotForm extends React.Component<IProps, IState> {
//   private html = `<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
//   <script>
//     hbspt.forms.create({
//     portalId: "6009734",
//     formId: "59d856ea-8256-41b7-a6f5-9d95437cd88a"
//   });
//   </script>`;

//   render() {
//     return <div dangerouslySetInnerHTML={{ __html: this.html }}/>
//   }
// }

//https://app.hubspot.com/forms/6009734/editor/59d856ea-8256-41b7-a6f5-9d95437cd88a/edit/form

export function HubspotForm () {
  const [termsAgreed, setTermsAgreed] = React.useState(false);
  const [privacyAgreed, setPrivacyAgreed] = React.useState(false);
  const [needMoreInfo, setNeedMoreInfo] = React.useState(false);

  const { data, isLoading, isError, handleSubmit } = useForm({
    portalId: '6009734',
    formId: '59d856ea-8256-41b7-a6f5-9d95437cd88a'
  })

  const handleSubmission = (e: React.FormEvent<any>) =>{
    if (!privacyAgreed || !termsAgreed){
      alert("You must agree to our terms and privacy statement to submit your application.")
      e.preventDefault();
      return;
    }
    handleSubmit();
  }

  React.useEffect(() =>{
    if (data && data.data){
      location.href = data.data.redirectUri
    }

  }, [data])

  React.useEffect(() =>{
    if (isError){
      alert('There was an issue submitting the form. Check your details and try again.');
    }
  }, [isError])

  return (
    <form onSubmit={handleSubmit}>
      <input required name="firstname" aria-label="First name" type="text" placeholder="First name" />
      <input required name="lastname" aria-label="Last name" type="text" placeholder="Last name" />
      <input required name="salutation" aria-label="Title" type="text" placeholder="Title" />
      <input required name="email" aria-label="Email" type="text" placeholder="Email" />
      <input required name="company" aria-label="Company" type="text" placeholder="Company" />
      <input required name="city" aria-label="City" type="text" placeholder="City" />
      <input required name="country" aria-label="Country" type="text" placeholder="Country" />
      <select required name="funding_stage" aria-label="Funding stage">
        <option value="" disabled selected>Funding stage</option>
        <option value="Bootstrapped">Bootstrapped</option>
        <option value="Pre seed">Pre seed</option>
        <option value="Post seed">Post seed</option>
        <option value="Series A">Series A</option>
        <option value="Series B +">Series B +</option>
      </select>
      <select aria-label="When are you looking to expand?" onChange={e => setNeedMoreInfo(e.target.selectedIndex === 4)} required name="looking_to_expand">
        <option value="" disabled selected>When are you looking to expand?</option>
        <option value="2019">2019</option>
        <option value="2020">2020</option>
        <option value="Not sure">Not sure</option>
        <option value="Other (please specify)">Other (Please specify)</option>
      </select>
      {needMoreInfo &&
        <textarea aria-label="More information about why you're looking to expand" placeholder="When are you looking to expand?" name="expand_more_info"></textarea>
      }
      <textarea aria-label="About your company" placeholder="Tell us about your company and why we should choose you?" name="tell_us_about_your_company"></textarea>
      <CheckboxInput
        onChange={e => setPrivacyAgreed(e.target.checked)}
        labelContent={<>I consent to being contacted to discuss our entry and have read Newfound’s <OutboundLink rel="noopener" target="_blank" href="https://newfound.global/privacy-policy">Privacy policy.</OutboundLink></>} />
        <CheckboxInput
        onChange={e => setTermsAgreed(e.target.checked)}
        labelContent={<>I have read and agreed to the <Link to="/terms" target="_blank">Terms and Conditions</Link> of this application.</>} />
        <Button pending={isLoading} type="submit">Submit</Button>      
    </form>
  )
}