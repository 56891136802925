import * as React from "react";
import { Grid, Row, Col } from "armstrong-react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Link, graphql, StaticQuery } from "gatsby";

import "./footer.scss";

interface IData {
  allMarkdownRemark: {
    edges: {
      node: {
        frontmatter: {
          addresses: {
            title: string
            line1: string
            line2: string
            phoneNumberDisplay: string
            phoneNumberDial: string
            emailAddress: string
          }[]
          footerBottomContent: string
        }
      }
    }[]
  }
}

export class Footer extends React.Component<{}, {}> {
  render() {
    return <StaticQuery
      query={graphql`
      query FooterQuery {
        allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/content/pages/footer.md" } }) {
          edges{
            node{
              frontmatter {
                addresses{
                  title
                  line1
                  line2
                  phoneNumberDisplay
                  phoneNumberDial
                  emailAddress
                }
                footerBottomContent
              }
            }
          }
        }
      }
    `}
      render={(data: IData) => (
        <footer className="bg-brand-secondary fg-white">
          <Grid className="fixed-width">
            <Row>
              <Col>
                <OutboundLink rel="noopener" target="_blank" href="https://newfound.global"><img alt="Newfound" src={require('../assets/logo_white.svg')} /></OutboundLink>
              </Col>
            </Row>
            <Row className="rs-small-2col">

              {data.allMarkdownRemark.edges[0].node.frontmatter.addresses.map(a =>

                <Col>
                  <p className="footer-title">{a.title}</p>
                  <p>
                    {a.line1}<br />
                    {a.line2}
                  </p>
                  <div className="mobile-only">
                    <a href={`tel:${a.phoneNumberDial}`}>{a.phoneNumberDisplay}</a>
                    <a href={`mailto:${a.emailAddress}`}>{a.emailAddress}</a>
                  </div>
                </Col>
              )}

              <Col width={220} />
              <Col width={110}>
                <p className="footer-title">Useful links</p>
                <OutboundLink rel="noopener" target="_blank" href="https://newfound.global/privacy-policy">Privacy Policy</OutboundLink>
                <OutboundLink rel="noopener" target="_blank" href="https://newfound.global/cookie-policy">Cookies Policy</OutboundLink>
              </Col>
            </Row>

            <Row className="no-mobile">
              {data.allMarkdownRemark.edges[0].node.frontmatter.addresses.map(a =>
                <Col>
                  <p className="footer-title">Contact</p>
                  <a href={`tel:${a.phoneNumberDial}`}>{a.phoneNumberDisplay}</a>
                  <a href={`mailto:${a.emailAddress}`}>{a.emailAddress}</a>
                </Col>
              )}
              <Col width={220} />
              <Col width={110}>
                <p className="footer-title">Follow us</p>
                <OutboundLink rel="noopener" target="_blank" href="https://www.facebook.com/newfoundglobal/?modal=admin_todo_tour">Facebook</OutboundLink>
                <OutboundLink rel="noopener" target="_blank" href="https://www.linkedin.com/company/14042972/admin/">LinkedIn</OutboundLink>
                <OutboundLink rel="noopener" target="_blank" href="https://twitter.com/newfoundglobal">Twitter</OutboundLink>
              </Col>
            </Row>

            <Row>
              <Col>
                <hr />
                <p className="company-info">
                  {data.allMarkdownRemark.edges[0].node.frontmatter.footerBottomContent}
                 
              </p>
              </Col>
            </Row>
          </Grid>
        </footer>
      )}
    />
  }

}
